import Vue from 'vue'

const OrderService = {
  all (param) {
    return Vue.http.get('order/index', param)
  },
  wmAll (param) {
    return Vue.http.get('order/wm', param)
  },
  // 创建包装费用
  freePrice (param = null) {
    const endpoint = 'order/free'

    if (param === null) {
      return Vue.http.get(endpoint)
    }
    return Vue.http.post(endpoint, param)
  },
  // 退款操作
  refund (param) {
    return Vue.http.post('order/refund', param)
  },
  // 查看订单详情
  viewOrder (param) {
    return Vue.http.get('order/view', param)
  }
}

export default OrderService
