<template>
  <el-dialog
    v-if="model"
    v-loading="loading"
    title="退款操作"
    :visible.sync="dialogVisible"
    width="60%"
    :before-close="handleClose"
  >
    <div class="display:flex;">
      <h3>订单号:{{model.order_no}}</h3>
      <p>实付款:<span
          class="price"
          style="font-size:20px;"
        >{{model.actual_payment}}</span> </p>
    </div>
    <el-table
      @selection-change="handleSelectionChange"
      :data="model.shop"
      border
      stripe
    >
      <el-table-column
        type="selection"
        :selectable="selectable"
        width="55"
      >
      </el-table-column>

      <el-table-column
        prop="shopName"
        label="商品名称"
      >
      </el-table-column>

      <el-table-column
        prop="shopSkuName"
        label="所选规格"
      >
      </el-table-column>

      <el-table-column
        prop="statusRefund"
        label="状态"
      >
        <template slot-scope="scope">
          <span
            class="goods-state"
            v-if="scope.row.isRefund == 1"
          >
            {{scope.row.statusRefund == 1?'已退':''}}
            {{scope.row.statusRefund == 2?'部分':''}}
            {{scope.row.statusRefund == 3?'退款中':''}}
            {{scope.row.statusRefund == 9?'退款失败':''}}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="actual_payment"
        label="价格"
      >
      </el-table-column>

      <el-table-column
        prop="shopSum"
        label="数量"
      >
      </el-table-column>

       <el-table-column
        prop="msg"
        label="原因"
      >
      </el-table-column>
    </el-table>

    <el-form
    style="margin-top:20px;"
    ref="form" label-width="80px">
        <el-form-item label="退款原因">
          <el-input
          style="width:300px;"
          v-model="msg"></el-input>
        </el-form-item>
    </el-form>

    <div style="margin-top:20px">
      <el-button
        plain
        v-if="$can('admin/order/refund')"
        type="danger"
        @click="refund()"
      >
        退款
      </el-button>
      <el-button
        plain
        @click="close()"
      >
        取消
      </el-button>
    </div>

  </el-dialog>
</template>

<script>
import OrderService from '@admin/services/OrderService'
import flatry from '@admin/utils/flatry'
export default {
  name: 'OrderRefund',
  data () {
    return {
      model: null,
      dialogVisible: false,
      loading: true,
      refundList: [],
      msg: ''
    }
  },

  methods: {
    async openModel (item) {
      const { data } = await flatry(OrderService.viewOrder({ id: item.id }))
      if (data) {
        console.log(data)
      }
      this.model = item
      this.dialogVisible = true
      this.loading = false
    },
    handleClose (done) {
      done()
    },
    close () {
      this.dialogVisible = false
    },
    refund () {
      let param = {
        id: this.model.id,
        arr: this.refundList,
        msg: this.msg,
        wm: 1
      }
      this.$emit('refund', param)
    },
    handleSelectionChange (val) {
      this.refundList = val
    },
    selectable (row, index) {
      return row.isRefund === '0'
    }
  }
}
</script>
<style lang='' scoped>
</style>
