<template>
  <div class="box">
    <!-- <el-button
      type="primary"
      size="medium"
      @click="downloadFile"
    >
      数据导出
    </el-button> -->
    <div class="box-toolbar">
      <div class="box-item">
        <el-input
          placeholder="订单号/手机号码/姓名"
          v-model="content"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-select
          v-model="shopId"
          placeholder="选择门店"
          @change="changeShopsStatus"
        >
          <el-option
            v-for="(item,index) in shops"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="box-item" style="width: 120px;">
        <el-select
          v-model="status"
          placeholder="选择状态"
          @change="changeTypesStatus"
        >
          <el-option
            v-for="(item,index) in Status"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="box-item">
        <el-button
          icon="el-icon-search"
          circle
          @click="searchGo()"
        ></el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      border
      :stripe="true"
      :data="lists"
      height="75vh"
    >

      <el-table-column
        width="60"
        prop="id"
        label="订单id"
      >
      </el-table-column>
      <el-table-column
        width="250"
        prop="order_no"
        label="订单号"
      >
      </el-table-column>

      <el-table-column
        prop="store_id"
        width="200"
        label="门店"
      >
        <template slot-scope="scope">
          {{scope.row.store.bsName}}
        </template>
      </el-table-column>

      <el-table-column
        width="150"
        prop="status"
        label="订单配送状态"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.wmInfo">
          <el-button
            v-if="scope.row.wmInfo.status == 0"
            type="primary"
            size='mini'
            plain
          >待商家接单</el-button>
          <el-button
            v-if="scope.row.wmInfo.status == 1"
            type="primary"
            size='mini'
            plain
          >待骑手接单</el-button>
          <el-button
            v-if="scope.row.wmInfo.status == 2"
            type="info"
            size='mini'
            plain
          >配送中</el-button>
          <el-button
            v-if="scope.row.wmInfo.status == 3"
            type="success"
            size='mini'
            plain
          >已完成</el-button>
          <el-button
            v-if="scope.row.wmInfo.status == 4"
            size='mini'
            plain
          >取餐中</el-button>
          <el-button
            v-if="scope.row.wmInfo.status == 5"
            type="danger"
            size='mini'
            plain
          >已退款</el-button>
          <el-button
            v-if="scope.row.wmInfo.status == 6"
            type="danger"
            size='mini'
            plain
          >部分退款</el-button>
            <el-button
            v-if="scope.row.wmInfo.status == 7"
            type="danger"
            size='mini'
            plain
          >待退款</el-button>
          <el-button
            v-if="scope.row.wmInfo.status == 8"
            type="warning"
            size='mini'
            plain
          >已驳回</el-button>
          <el-button
            v-if="scope.row.wmInfo.status == 9"
            type="info"
            size='mini'
            plain
          >交易关闭</el-button>
          <el-button
            v-if="scope.row.wmInfo.status == 99"
            type="info"
            size='mini'
            plain
          >商家已取消</el-button>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="actual_payment"
        width="150"
        label="支付金额"
      >
      </el-table-column>

      <el-table-column
        prop="message"
        label="配送类型"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.wmInfo">
            {{scope.row.wmInfo.wm_type == 0?'自配送':''}}
            {{scope.row.wmInfo.wmSet?scope.row.wmInfo.wmSet.name:''}}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        width="180"
        prop="payment_at"
        label="付款时间"
      >
      </el-table-column>

      <el-table-column
        prop="user_name"
        label="付款人"
      >
      </el-table-column>
      <el-table-column
        width="120"
        prop="user_number"
        label="联系方式"
      >
      </el-table-column>

      <el-table-column
        prop="message"
        label="订单备注"
      >
      </el-table-column>

      <el-table-column
        fixed="right"
        width="300"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            plain
            size="mini"
            type="primary"
            v-if="$can('admin/order/view')"
            @click.native="openViewOrder(scope.row)"
          >
            查看
          </el-button>
          <el-button
            plain
            size="mini"
            type="danger"
            v-if="scope.row.wmInfo && scope.row.wmInfo.status == 0"
            @click.native="okJieOrder(scope.row)"
          >接单</el-button>

          <el-button
            plain
            size="mini"
            type="danger"
            v-if="scope.row.wmInfo && scope.row.wmInfo.status == 4 && scope.row.wmInfo.wm_type == 0"
            @click.native="goPs(scope.row)"
          >开始配送</el-button>

          <el-button
            plain
            size="mini"
            type="danger"
            v-if="scope.row.wmInfo && scope.row.wmInfo.status == 2 && scope.row.wmInfo.wm_type == 0"
            @click.native="goPsOver(scope.row)"
          >配送完成</el-button>

          <el-button
            plain
            size="mini"
            type="warning"
            v-if="scope.row.wmInfo && scope.row.status == 7"
            @click.native="openRefundOrder(scope.row)"
          >驳回</el-button>
          <el-button
            plain
            size="mini"
            type="danger"
            v-if="$can('admin/order/refund')"
            @click.native="openRefundOrder(scope.row)"
          >退款</el-button>

        </template>
      </el-table-column>
    </el-table>
    <page
      :pageSize="limit"
      :count="count"
      @change="pageChange"
      @changeSize="changeSize"
    >
    </page>
    <view-order ref="view" />
    <refund
      ref="refund"
      @refund="refundOrder"
    />
  </div>
</template>

<script>
import Page from '@admin/components/Pagination'
import OrderService from '@admin/services/OrderService'
import FileService from '@admin/services/FileService'
import pagination from '@admin/mixins/PaginationMixin'
import ShopService from '@admin/services/ShopService'
import flatry from '@admin/utils/flatry'
import refund from './components/wmrefund'
import viewOrder from './components/view'

export default {
  components: { Page, refund, viewOrder },
  data () {
    const Status = [
      {
        id: '',
        name: '全部状态'
      },
      {
        id: 0,
        name: '待商家接单'
      },
      {
        id: 1,
        name: '待骑手接单'
      },
      {
        id: 2,
        name: '配送中'
      },
      {
        id: 3,
        name: '已完成'
      },
      {
        id: 5,
        name: '已退款'
      },
      {
        id: 6,
        name: '部分退款'
      },
      {
        id: 9,
        name: '交易已关闭'
      }
    ]
    return {
      loading: true,
      function: function (param) {
        return OrderService.wmAll(param)
      },
      content: '',
      // 以下字段搜索使用
      type: 3,
      status: '',
      Status: Status,
      shopId: '',
      shops: []
    }
  },
  mixins: [pagination],
  methods: {
    async searchGo () {
      this.page = 1
      let param = {
        content: this.content,
        store_id: this.shopId,
        status: this.status,
        type: this.type
      }
      this.where = param
      await this.getList(param)
    },
    // 商家接单
    async okJieOrder (item) {
      const { data } = await flatry(ShopService.jieDan(item))
      if (data) {
        this.$message.success(data.msg)
        this.searchGo()
      }
    },
    // 开始配送
    async goPs (item) {
      const { data } = await flatry(ShopService.goPs(item))
      if (data) {
        this.$message.success(data.msg)
        this.searchGo()
      }
    },
    async goPsOver (item) {
      const { data } = await flatry(ShopService.goPsOver(item))
      if (data) {
        this.$message.success(data.msg)
        this.searchGo()
      }
    },
    async getShops () {
      const { data } = await flatry(ShopService.shops())
      let shop = [
        {
          id: '',
          name: '全部店铺'
        }
      ]
      if (data) {
        this.shops = shop.concat(data.data)
      } else {
        this.shops = shop
      }
    },
    changeShopsStatus () {
      this.searchGo()
    },
    // 切换type 状态
    changeTypesStatus () {
      this.searchGo()
    },
    // 退款请求
    async refundOrder (item) {
      const { data } = await flatry(OrderService.refund(item))
      if (data) {
        this.$message.success(data.msg)

        this.$refs.refund.close()

        this.searchGo()
      }
    },
    openRefundOrder (item) {
      this.$refs.refund.openModel(item)
    },
    openViewOrder (item) {
      this.$refs.view.openModel(item)
    },
    async downloadFile () {
      let param = {
        type: 'wm-order'
      }
      const { data } = await flatry(FileService.downloadFile(null, param))
      if (data) {
        console.log(data)
      }
    }
  },
  async created () {
    await this.getShops()
  }
}
</script>

<style scoped>
</style>
