<template>
  <el-dialog
    v-if="model"
    v-loading="loading"
    title="订单详情"
    :visible.sync="dialogVisible"
    width="80%"
    :before-close="handleClose"
  >
    <div class="display:flex;">
      <h3>订单号:{{model[0].order_no}}</h3>
    </div>
    <el-table
      :data="model"
      border
      stripe
    >
      <el-table-column label="订单号">
        {{model[0].order_no}}
      </el-table-column>

      <el-table-column label="门店">
        {{model[0].store.bsName}}
      </el-table-column>

      <el-table-column
        prop="actual_payment"
        label="订单状态"
      >
        <el-button
          v-if="model[0].status == 1"
          type="info"
          size='mini'
          plain
        >未支付</el-button>
        <el-button
          v-if="model[0].status == 2"
          type="primary"
          size='mini'
          plain
        >已支付</el-button>
        <el-button
          v-if="model[0].status == 3"
          type="success"
          size='mini'
          plain
        >已完成</el-button>
        <el-button
          v-if="model[0].status == 5"
          type="warning"
          size='mini'
          plain
        >已退款</el-button>
        <el-button
          v-if="model[0].status == 6"
          type="warning"
          size='mini'
          plain
        >部分退款</el-button>
        <el-button
          v-if="model[0].status == 8"
          type="warning"
          size='mini'
          plain
        >已关闭</el-button>
        <el-button
          v-if="model[0].status == 99"
          type="info"
          size='mini'
          plain
        >交易已关闭</el-button>

        <div v-if="model[0].wmInfo">
          <el-button
            v-if="model[0].wmInfo.status == 0"
            type="primary"
            size='mini'
            plain
          >待商家接单</el-button>
          <el-button
            v-if="model[0].wmInfo.status == 1"
            type="primary"
            size='mini'
            plain
          >待骑手接单</el-button>
          <el-button
            v-if="model[0].wmInfo.status == 2"
            type="info"
            size='mini'
            plain
          >配送中</el-button>
          <el-button
            v-if="model[0].wmInfo.status == 8"
            type="warning"
            size='mini'
            plain
          >已驳回</el-button>
          <el-button
            v-if="model[0].wmInfo.status == 9"
            type="info"
            size='mini'
            plain
          >交易关闭</el-button>
        </div>
      </el-table-column>

      <el-table-column label="订单类型">
        {{model[0].type == 1?'堂食':''}}
        {{model[0].type == 2?'带走':''}}
        {{model[0].type == 3?'外卖':''}}
      </el-table-column>

      <el-table-column label="商品金额">
        {{model[0].shop_price}}
      </el-table-column>

      <el-table-column label="支付金额">
        <p>支付金额: {{model[0].payment}}</p>
        <div v-if="model[0].price.backPrice">
          <p
            style="color:red;"
            v-if="model[0].price.backPrice != 0"
          >找零: {{model[0].price.backPrice}}</p>
          <p>实际收款：{{model[0].actual_payment}}</p>
        </div>
      </el-table-column>

      <el-table-column label="备注">
        {{model[0].message}}
      </el-table-column>

      <el-table-column label="付款时间">
        {{model[0].payment_at}}
      </el-table-column>

      <el-table-column label="付款人">
        {{model[0].user_name}}
      </el-table-column>
      <el-table-column label="联系方式">
        {{model[0].user_number}}
      </el-table-column>
    </el-table>

    <el-table
      style="margin-top:50px;"
      :data="model"
      border
      stripe
    >
      <el-table-column label="支付方式">
        {{model[0].payment_type_name}}
      </el-table-column>

      <el-table-column label="优惠方式">
        <div v-if="model[0].price">
          <p v-if="model[0].price.discount_price != 0">会员折扣: {{model[0].price.discount_price}}</p>
          <p v-if="model[0].price.free_price != 0">整单折扣: {{model[0].price.free_price}}</p>
          <p v-if="model[0].price.coupon_price != 0">{{model[0].coupon_id_name}}: {{model[0].price.coupon_price}}</p>
        </div>
        <div v-else>
          <p>{{model[0].coupon_id_name}}</p>
          <p v-if="model[0].syy && model[0].syy.discount < 10">整单折扣:{{model[0].syy.discount}}折</p>
        </div>
      </el-table-column>

      <el-table-column label="桌号">
        {{model[0].type_number}}
      </el-table-column>
    </el-table>

    <el-table
      style="margin-top:50px;"
      :data="model[0].shop"
      border
      stripe
    >
      <el-table-column
        prop="shopName"
        label="商品名称"
      >
      </el-table-column>

      <el-table-column
        prop="shopSkuName"
        label="所选规格"
      >
      </el-table-column>

      <el-table-column
        prop="statusRefund"
        label="状态"
      >
        <template slot-scope="scope">
          <span
            class="goods-state"
            v-if="scope.row.isRefund == 1"
          >
            {{scope.row.statusRefund == 1?'已退':''}}
            {{scope.row.statusRefund == 2?'部分':''}}
            {{scope.row.statusRefund == 3?'退款中':''}}
            {{scope.row.statusRefund == 9?'退款失败':''}}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="actual_payment"
        label="价格"
      >
      </el-table-column>

      <el-table-column
        prop="shopSum"
        label="数量"
      >
      </el-table-column>

    </el-table>
  </el-dialog>
</template>

<script>
import OrderService from '@admin/services/OrderService'
import flatry from '@admin/utils/flatry'

export default {
  name: 'OrderView',
  data () {
    return {
      model: null,
      dialogVisible: false,
      loading: true
    }
  },

  methods: {
    async openModel (item) {
      const { data } = await flatry(
        OrderService.viewOrder({ id: item.id })
      )
      if (data) {
        this.model = data.data
        this.dialogVisible = true
        this.loading = false
      }
    },
    handleClose (done) {
      done()
    }
  }
}
</script>
<style lang='' scoped>
</style>
